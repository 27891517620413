var _a;
import React from "react";
import { JobType } from "@utils/enums";
import Icon from "@iconify/react";
import PdfsIcon from "@iconify/icons-ion/documents";
import PdfIcon from "@iconify/icons-ion/document-text";
import ArrowForward from "@iconify/icons-ion/arrow-forward";
import clx from "classnames";
var styles = require("./styles.scss");
var jobTypeMapping = (_a = {},
    _a[JobType.ALL_TO_ONE] = "Covert many documents into one PDF file",
    _a[JobType.ONE_BY_ONE] = "Convert each document to multiple PDF files",
    _a);
var JobTypeIcon = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "job-" + props.id },
            React.createElement(Icon, { icon: PdfsIcon, className: clx(styles.pdfIcon) }),
            React.createElement(Icon, { icon: ArrowForward, className: clx(styles.arrowIcon) }),
            React.createElement(Icon, { icon: props.type === JobType.ONE_BY_ONE ? PdfsIcon : PdfIcon, className: clx(styles.pdfIcon) }),
            !props.onlyIcon && (React.createElement("span", { className: "ml-2" }, jobTypeMapping[props.type])))));
};
export default JobTypeIcon;
