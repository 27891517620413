import { __read } from "tslib";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
var ErrorMessageModal = function (props) {
    var _a = __read(useState(false), 2), modalStatus = _a[0], toggleModal = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: function () { return toggleModal(!modalStatus); }, variant: "outline-danger", size: "sm" }, "Show"),
        React.createElement(Modal, { show: modalStatus, onHide: function () { return toggleModal(!modalStatus); } },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Error details")),
            React.createElement(Modal.Body, null, props.errorMessage))));
};
export default ErrorMessageModal;
