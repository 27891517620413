import React from "react";
import { Button, Col, Container, Navbar } from "react-bootstrap";
import { AppRoutes } from "@utils/enums";
import { Link } from "react-router-dom";
import GoToJob from "@components/GoToJob/GoToJob";
import clx from "classnames";
import Icon from "@iconify/react";
import UploadIcon from "@iconify/icons-ion/cloud-upload";
var styles = require("./styles.scss");
var SubNavigation = function () { return (React.createElement(Navbar, { bg: "light", className: clx(styles.subNavigation) },
    React.createElement(Container, null,
        React.createElement(Col, null,
            React.createElement("div", { className: styles.searchAndUpload },
                React.createElement(GoToJob, null),
                React.createElement("div", { className: styles.uploadDocumentWrapper },
                    React.createElement(Link, { to: AppRoutes.UploadDocument },
                        React.createElement(Button, { color: "primary" },
                            React.createElement(Icon, { icon: UploadIcon }),
                            " ",
                            React.createElement("span", { className: styles.uploadButtonDescription }, "Upload documents"))))))))); };
export default SubNavigation;
