import { __read, __spread } from "tslib";
import React from "react";
import { AvailableFilters, JobStatus } from "@utils/enums";
import JobStatusIcon from "@components/Icon/JobStatusIcon";
import { getFilters } from "@store/selectors/pdf";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
var jobStatusMapping = [
    JobStatus.NEW,
    JobStatus.VALIDATED,
    JobStatus.IN_PROGRESS,
    JobStatus.FINISHED,
    JobStatus.FAILED,
    JobStatus.APP_ERROR
];
var JobStatusFilter = function (props) {
    var filters = useSelector(getFilters);
    var selectFilter = function (filter) {
        var statuses = filters[AvailableFilters.STATUS_NAME];
        var options = [];
        if (!statuses) {
            options.push(filter);
        }
        else {
            options = statuses.includes(filter)
                ? statuses.filter(function (i) { return i !== filter; })
                : __spread(statuses, [filter]);
        }
        props.onChange({
            filterName: AvailableFilters.STATUS_NAME,
            filterValue: options
        });
    };
    var isJobStatusChecked = function (filter) {
        var statuses = filters[AvailableFilters.STATUS_NAME];
        return !statuses ? false : statuses.includes(filter);
    };
    return (React.createElement(DropdownButton, { title: "Job Status", id: "jobStatus", variant: "secondary" }, jobStatusMapping.map(function (item, index) { return (React.createElement(Dropdown.Item, { eventKey: "" + (index + 1), key: item, onClick: function () { return selectFilter(item); }, active: isJobStatusChecked(item) },
        React.createElement(JobStatusIcon, { status: item }))); })));
};
export default JobStatusFilter;
