import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clx from "classnames";
import { getOrdering } from "@store/selectors/pdf";
import { changeOrderingDirection, changeOrderingKey } from "@store/actions/pdf";
import CaretDown from "@iconify/icons-ion/caret-down-outline";
import CaretUp from "@iconify/icons-ion/caret-up-outline";
import Icon from "@iconify/react";
var styles = require("./styles.scss");
var TableOrdering = function (props) {
    var _a, _b;
    var ordering = useSelector(getOrdering);
    var dispatch = useDispatch();
    var isSelected = function () { return props.orderBy === ordering.OrderBy; };
    var onIndicatorClick = function () {
        return dispatch(isSelected()
            ? changeOrderingDirection()
            : changeOrderingKey(props.orderBy));
    };
    return (React.createElement("div", { className: clx(styles.orderIndicator), onClick: onIndicatorClick },
        React.createElement(Icon, { icon: CaretUp, className: clx(styles.indicator, (_a = {},
                _a[styles.active] = ordering.Ascending && isSelected(),
                _a)) }),
        React.createElement(Icon, { icon: CaretDown, className: clx(styles.indicator, (_b = {},
                _b[styles.active] = !ordering.Ascending && isSelected(),
                _b)) })));
};
export default TableOrdering;
