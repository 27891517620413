import { __read } from "tslib";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import clx from "classnames";
var styles = require("./styles.scss");
var GoToJob = function () {
    var _a = __read(useState(""), 2), jobId = _a[0], setJobId = _a[1];
    var history = useHistory();
    var handleKeyPress = function (e) {
        if (e.key === "Enter" || e.charCode === 0) {
            setJobId("");
            history.push("/job/" + jobId);
        }
    };
    return (React.createElement(Form.Control, { type: "text", name: "guid", placeholder: "Type job id and hit enter", onKeyPress: handleKeyPress, value: jobId, onChange: function (e) { return setJobId(e.target.value); }, className: clx(styles.goToJob) }));
};
export default GoToJob;
