import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilters } from "@store/selectors/pdf";
import { removeFilter } from "@store/actions/pdf";
import clx from "classnames";
import Icon from "@iconify/react";
import RemoveIcon from "@iconify/icons-ion/close-circle-outline";
var styles = require("./styles.scss");
var RemoveFilterIcon = function (props) {
    var filters = useSelector(getFilters);
    var dispatch = useDispatch();
    var isFilterEnabled = function () { return Object.keys(filters).includes(props.filter); };
    if (!isFilterEnabled()) {
        return null;
    }
    return (React.createElement("div", { id: props.filter, onClick: function () { return dispatch(removeFilter(props.filter)); } },
        React.createElement(Icon, { icon: RemoveIcon, className: clx(styles.removeFilter) })));
};
export default RemoveFilterIcon;
