import { __read } from "tslib";
import React, { useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import clx from "classnames";
var styles = require("./styles.scss");
var Select = function (_a) {
    var onChange = _a.onChange, selectOptions = _a.selectOptions;
    var _b = __read(useState("10"), 2), paginationSize = _b[0], setPaginationSize = _b[1];
    var setPagination = function (value) {
        setPaginationSize(value);
        onChange({ PageSize: Number(value), PageNumber: 1 });
    };
    return (React.createElement("div", { className: clx(styles.itemsSelectWrapper) },
        React.createElement(Form.Label, null, "Items per page:"),
        React.createElement(DropdownButton, { id: "set-pagination", title: paginationSize, variant: "secondary" }, selectOptions.map(function (option) { return (React.createElement(Dropdown.Item, { key: "optione" + option, eventKey: "" + option, onSelect: setPagination, active: Number(paginationSize) === option }, option)); }))));
};
export default Select;
