var _a, _b;
import { __assign, __rest } from "tslib";
import { createReducer } from "@utils/storeUtils";
import { AvailableFilters, SortingKeys } from "@utils/enums";
import subDays from "date-fns/sub_days";
import startOfDay from "date-fns/start_of_day";
import endOfDay from "date-fns/end_of_day";
var daysToFetch = 2;
export var initialState = {
    jobs: [],
    jobInputFiles: [],
    jobOutputFiles: [],
    totalItems: 0,
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    nextPageNumber: 0,
    previousPageNumber: 0,
    isLoading: true,
    filters: (_a = {},
        _a[AvailableFilters.CREATED_FROM] = startOfDay(subDays(Date.now(), daysToFetch)),
        _a[AvailableFilters.CREATED_TO] = endOfDay(Date.now()),
        _a),
    ordering: {
        OrderBy: SortingKeys.CREATED,
        Ascending: false
    }
};
var fetchPdfJobs = function (state, action) {
    var _a = action.payload, elements = _a.elements, meta = __rest(_a, ["elements"]);
    return __assign(__assign(__assign({}, state), { jobs: elements, isLoading: false }), meta);
};
var setFilters = function (state, action) { return (__assign(__assign({}, state), { filters: __assign({}, action.payload) })); };
var setOrdering = function (state, action) { return (__assign(__assign({}, state), { ordering: __assign({}, action.payload) })); };
var setLoadingStatus = function (state, action) { return (__assign(__assign({}, state), { isLoading: action.type === "FETCH_PDF_JOBS" })); };
var actionReducerMap = (_b = {},
    _b["FETCH_PDF_JOBS_SUCCESS"] = fetchPdfJobs,
    _b["FETCH_PDF_JOBS_FAIL"] = setLoadingStatus,
    _b["FETCH_PDF_JOBS"] = setLoadingStatus,
    _b["SET_FILTERS"] = setFilters,
    _b["SET_ORDERING"] = setOrdering,
    _b);
export default createReducer(initialState, actionReducerMap);
