import React from "react";
import clx from "classnames";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
var styles = require("./styles.scss");
var CircleIcon = function (props) {
    return (React.createElement("div", { className: clx(styles.circleIcon) },
        React.createElement(Button, { variant: "info" },
            React.createElement(Icon, { icon: props.icon })),
        props.description && React.createElement("small", null, props.description)));
};
export default CircleIcon;
