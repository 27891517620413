import { format } from "date-fns";
var displayDate = function (date, dateFormat) {
    if (dateFormat === void 0) { dateFormat = "DD.MM.YYYY"; }
    return format(date, dateFormat);
};
var displayTime = function (date, timeFormat) {
    if (timeFormat === void 0) { timeFormat = "HH:mm:ss"; }
    return format(date, timeFormat);
};
export { displayDate, displayTime };
