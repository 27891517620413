import { combineReducers } from "redux";
import { reducer as oidcReducer } from "redux-oidc";
import pdfReducer from "@store/reducer/pdf";
import uploadReducer from "@store/reducer/upload";
import { appsReducer } from "@talentech/application-bar";
var reducer = combineReducers({
    oidc: oidcReducer,
    pdf: pdfReducer,
    upload: uploadReducer,
    apps: appsReducer
});
export default reducer;
