import React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import Routes from "./routes";
import store from "@store/store";
import userManager from "@auth/userManager";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "@components/Header/Header";
import { BrowserRouter as Router } from "react-router-dom";
import { NavigationPersistor } from "@talentech/login";
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(OidcProvider, { store: store, userManager: userManager },
        React.createElement(Router, null,
            React.createElement(React.Fragment, null,
                React.createElement(NavigationPersistor, null),
                React.createElement(Header, null),
                React.createElement(Routes, null))))), document.getElementById("app"));
