import { createUserManager } from "redux-oidc";
var userManagerConfig = {
    authority: process.env.HRID_API_URL,
    automaticSilentRenew: true,
    client_id: "hrmts-pdfservice-js-app",
    filterProtocolClaims: true,
    loadUserInfo: true,
    redirect_uri: window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/callback.html",
    response_type: "code",
    scope: "openid profile offline_access hrmts-pdfservice-api-scope",
    silent_redirect_uri: window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/silent_renew.html",
    acr_values: "userType:Employee",
    post_logout_redirect_uri: process.env.PUBLIC_URL,
    monitorSession: false
};
var userManager = createUserManager(userManagerConfig);
export default userManager;
