import RocketIcon from "@iconify/icons-ion/ios-rocket";
import PeopleIcon from "@iconify/icons-ion/ios-people";
import DocumentIcon from "@iconify/icons-ion/ios-document";
export var appsMock = [
    {
        id: "dashboard",
        title: "Dashboard",
        icon: RocketIcon,
        appUrl: "https://test-dashboard.hrmts.net/"
    },
    {
        id: "recruiter",
        title: "Recruiter",
        icon: PeopleIcon,
        appUrl: "https://test-recruiter.hrmts.net/"
    },
    {
        id: "pdf-service",
        title: "PDF Service",
        icon: DocumentIcon,
        appUrl: "https://test-pdfservice.hrmts.net/"
    }
];
