var _a;
import { __assign, __read } from "tslib";
import React, { useEffect, useRef, useState } from "react";
import { FilePond as IFilePond, registerPlugin } from "react-filepond";
import { useDispatch, useSelector } from "react-redux";
import { clearUploadStatus, uploadFiles } from "@store/actions/pdf";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { getUploadStatus } from "@store/selectors/upload";
import { Alert, Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { PdfGenerator, PdfTypes, UploadJobType } from "@utils/enums";
import SpinnerLoader from "@components/SpinnerLoader/SpinnerLoader";
import clx from "classnames";
import DropdownSelect from "@components/DropdownSelect/DropdownSelect";
registerPlugin(FilePondPluginFileEncode, FilePondPluginImagePreview);
var conversionTypesMapping = (_a = {},
    _a[UploadJobType.ONE_BY_ONE] = "One by one",
    _a[UploadJobType.ALL_TO_ONE] = "All files to one PDF",
    _a);
var conversionTypes = [UploadJobType.ONE_BY_ONE, UploadJobType.ALL_TO_ONE];
var UploadDocuments = function () {
    var _a = __read(useState({}), 2), pdfGenerationOptions = _a[0], setPdfGenerationOptions = _a[1];
    var _b = __read(useState(false), 2), isSendingFiles = _b[0], setSendingFilesStatus = _b[1];
    var _c = __read(useState(false), 2), alertVisibility = _c[0], setAlertVisibility = _c[1];
    var uploadStatus = useSelector(getUploadStatus);
    var _d = __read(useState([]), 2), files = _d[0], setFiles = _d[1];
    var dispatch = useDispatch();
    useEffect(function () {
        if (uploadStatus && pondRef.current) {
            pondRef.current.removeFiles();
            setFiles([]);
        }
        if (uploadStatus !== null) {
            setAlertVisibility(true);
        }
    }, [uploadStatus]);
    useEffect(function () {
        if (uploadStatus && files.length === 0) {
            setSendingFilesStatus(false);
        }
    }, [files]);
    useEffect(function () {
        return function () {
            dispatch(clearUploadStatus());
        };
    }, []);
    var _e = __read(useState(false), 2), isPrepared = _e[0], setPrepareStatus = _e[1];
    var _f = __read(useState(UploadJobType.ONE_BY_ONE), 2), jobType = _f[0], setJobType = _f[1];
    var pondRef = useRef();
    var FilePond = IFilePond;
    var handleUpload = function () {
        setPrepareStatus(false);
        setSendingFilesStatus(true);
        var uploadedFiles = pondRef.current ? pondRef.current.getFiles() : [];
        var attachments = uploadedFiles.map(function (singleFile) { return ({
            content: singleFile.getFileEncodeBase64String(),
            name: singleFile.filename
        }); });
        dispatch(uploadFiles({
            jobType: jobType,
            data: __assign({ jobId: "", performVirusScanning: true, attachments: attachments }, pdfGenerationOptions)
        }));
    };
    var handleFileChange = function (fileItems) {
        setFiles(fileItems.map(function (fileItem) { return fileItem.file; }));
    };
    var handleFileRemove = function (error, fileToRemove) {
        if (!pondRef.current) {
            return;
        }
        var filesAfterRemove = pondRef.current
            .getFiles()
            .filter(function (file) { return file.id !== fileToRemove.id; });
        setFiles(filesAfterRemove.map(function (file) { return file.file; }));
        if (pondRef.current && pondRef.current.getFiles().length === 0) {
            setPrepareStatus(false);
        }
    };
    var onDropdownChange = function (dropdown) {
        if (dropdown[Object.keys(dropdown)[0]]) {
            setPdfGenerationOptions(__assign(__assign({}, pdfGenerationOptions), dropdown));
        }
    };
    var isButtonActive = function (selectedJobType) {
        return jobType === selectedJobType;
    };
    return (React.createElement(Container, null,
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("h5", null, "Conversion Type"))),
        React.createElement(Row, null,
            React.createElement(Col, { md: "auto", className: "mb-2" },
                React.createElement(ButtonGroup, null, conversionTypes.map(function (i) { return (React.createElement(Button, { key: i, variant: isButtonActive(i) ? "primary" : "secondary", onClick: function () { return setJobType(i); }, active: isButtonActive(i) }, conversionTypesMapping[i])); }))),
            React.createElement(Col, { className: "d-flex justify-content-md-end mb-2" },
                React.createElement(DropdownSelect, { onDropdownSelect: onDropdownChange, options: Object.keys(PdfTypes).map(function (item) { return item; }), dropdownName: "pdf-types", dropdownPlaceholder: "Select PDF Type", dropdownKey: "pdfType" })),
            React.createElement(Col, { md: "auto", className: "mb-2" },
                React.createElement(DropdownSelect, { onDropdownSelect: onDropdownChange, options: Object.keys(PdfGenerator).map(function (item) { return item; }), dropdownName: "pdf-generators", dropdownPlaceholder: "Select PDF Generator", dropdownKey: "pdfGenerator" }))),
        uploadStatus && uploadStatus.uploaded && (React.createElement(Alert, { variant: "success", show: alertVisibility, onClose: function () { return setAlertVisibility(!alertVisibility); }, dismissible: true },
            uploadStatus.message,
            " ",
            React.createElement(Link, { to: "/job/" + uploadStatus.jobId },
                "See your job: ",
                uploadStatus.jobId))),
        uploadStatus &&
            !uploadStatus.uploaded &&
            uploadStatus.uploaded !== null && (React.createElement(Alert, { variant: "danger", show: alertVisibility, onClose: function () { return setAlertVisibility(!alertVisibility); }, dismissible: true }, uploadStatus.message)),
        isSendingFiles && React.createElement(SpinnerLoader, null),
        React.createElement("div", { className: clx({ "d-none": isSendingFiles }) },
            React.createElement(FilePond, { ref: pondRef, instantUpload: false, files: files, allowMultiple: true, allowRevert: false, onupdatefiles: handleFileChange, onpreparefile: function () { return setPrepareStatus(true); }, onremovefile: handleFileRemove })),
        React.createElement(Button, { disabled: !isPrepared, onClick: function () { return handleUpload(); }, color: "primary" }, "Upload")));
};
export default UploadDocuments;
