import { __rest } from "tslib";
import { createSelector } from "reselect";
var pdfSelector = function (state) { return state.pdf; };
var singleJobPdfSelector = function (state, id) { return ({
    state: pdfSelector(state),
    id: id
}); };
var getPdfJobs = createSelector(pdfSelector, function (pdf) { return pdf.jobs; });
var getSingleJob = createSelector(singleJobPdfSelector, function (singleJobPdf) {
    return singleJobPdf.state.jobs.find(function (job) { return job.id === singleJobPdf.id; });
});
var getPagination = createSelector(pdfSelector, function (pdf) {
    var jobs = pdf.jobs, pagination = __rest(pdf, ["jobs"]);
    return pagination;
});
var getPageSize = createSelector(pdfSelector, function (pdf) {
    var pageSize = pdf.pageSize;
    return pageSize ? pageSize : 10;
});
var getFilters = createSelector(pdfSelector, function (pdf) { return pdf.filters; });
var getOrdering = createSelector(pdfSelector, function (pdf) { return pdf.ordering; });
var getLoadingStatus = createSelector(pdfSelector, function (pdf) { return pdf.isLoading; });
export { getPdfJobs, getPagination, getSingleJob, getPageSize, getFilters, getOrdering, getLoadingStatus };
