import { __assign } from "tslib";
import store from "@store/store";
import Axios from "axios";
import { getOidcAccessToken } from "@store/selectors/oidc";
import { getFilters, getOrdering, getPageSize } from "@store/selectors/pdf";
import qs from "qs";
export var HttpMethodsList;
(function (HttpMethodsList) {
    HttpMethodsList["GET"] = "GET";
    HttpMethodsList["POST"] = "POST";
})(HttpMethodsList || (HttpMethodsList = {}));
function apiRequest(url, params, addParams, method, customHeaders, data) {
    if (params === void 0) { params = {}; }
    if (addParams === void 0) { addParams = true; }
    if (method === void 0) { method = HttpMethodsList.GET; }
    if (customHeaders === void 0) { customHeaders = {}; }
    if (data === void 0) { data = {}; }
    var token = getOidcAccessToken(store.getState());
    var PageSize = addParams ? getPageSize(store.getState()) : {};
    var getCurrentFilters = addParams ? getFilters(store.getState()) : {};
    var getCurrentOrdering = addParams ? getOrdering(store.getState()) : {};
    return Axios({
        url: process.env.PDFSERVICE_API_URL + "/v1" + url,
        method: method,
        params: __assign(__assign(__assign({ PageSize: PageSize }, params), getCurrentFilters), getCurrentOrdering),
        paramsSerializer: function (queryParams) {
            return qs.stringify(queryParams, { arrayFormat: "repeat" });
        },
        headers: __assign({ Authorization: "Bearer " + token }, customHeaders),
        data: data
    });
}
function fileRequest(url) {
    var token = getOidcAccessToken(store.getState());
    return Axios({
        url: process.env.PDFSERVICE_API_URL + "/v1" + url,
        method: "GET",
        headers: {
            Authorization: "Bearer " + token
        },
        responseType: "blob"
    });
}
export { apiRequest, fileRequest };
