import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { AvailableFilters, JobType } from "@utils/enums";
import JobTypeIcon from "@components/Icon/JobTypeIcon";
import { connect } from "react-redux";
import { getFilters } from "@store/selectors/pdf";
var jobTypeMapping = [JobType.ONE_BY_ONE, JobType.ALL_TO_ONE];
var JobTypeFilter = function (props) {
    var selectFilter = function (filter) {
        props.onChange({
            filterName: AvailableFilters.TYPE_NAME,
            filterValue: filter
        });
    };
    return (React.createElement(DropdownButton, { title: "Job Type", id: "jobType", variant: "secondary" }, jobTypeMapping.map(function (item) { return (React.createElement(Dropdown.Item, { key: item, onClick: function () { return selectFilter(item); }, active: item === props.filters[AvailableFilters.TYPE_NAME] },
        React.createElement(JobTypeIcon, { id: item.toString(), type: item }))); })));
};
var mapStateToProps = function (state) { return ({
    filters: getFilters(state)
}); };
export default connect(mapStateToProps)(JobTypeFilter);
