import React from "react";
import SubNavigation from "./SubNavigation";
import clx from "classnames";
import { getOidcUser, getOidcUserRoles } from "@store/selectors/oidc";
import { useSelector } from "react-redux";
import { ApplicationBar } from "@talentech/application-bar";
import "@talentech/application-bar/dist/index.css";
import userManager from "@auth/userManager";
import { isAuthorizedUser } from "@utils/user";
import { userUtils } from "@talentech/login";
var styles = require("./styles.scss");
var Header = function () {
    var user = useSelector(getOidcUser);
    var roles = useSelector(getOidcUserRoles);
    if (!user || user.expired || !isAuthorizedUser(roles)) {
        return null;
    }
    return (React.createElement("header", { id: "topnav", className: clx(styles.mainHeader) },
        React.createElement(ApplicationBar, { user: user, userMenu: [], onLogout: function () { return userUtils.hardLogout(userManager); } }),
        React.createElement(SubNavigation, null)));
};
export default Header;
