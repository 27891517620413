import { __read } from "tslib";
import JobStatusIcon from "@components/Icon/JobStatusIcon";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import JobTypeIcon from "@components/Icon/JobTypeIcon";
import { displayDate, displayTime } from "@utils/date";
import { loadSinglePdfJob } from "@store/actions/pdf";
import AttachmentFilesList from "@components/AttachmentFilesList/AttachmentFilesList";
import SpinnerLoader from "@components/SpinnerLoader/SpinnerLoader";
import CircleIcon from "@components/Icon/CircleIcon";
import kibanaIcon from "@iconify/icons-simple-icons/kibana";
import { kibanaJobDetails } from "@utils/urls";
import PdfLayoutJsonViewer from "@components/PdfLayoutJsonViewer/pdfLayoutJsonViewer";
var JobDetails = function (props) {
    var jobId = props.match.params.id;
    var _a = __read(useState(), 2), pdfJob = _a[0], setPdfJob = _a[1];
    useEffect(function () {
        props
            .loadSinglePdfJob({ jobId: jobId })
            .then(function (response) { return setPdfJob(response); })
            .catch(function () { return props.history.push("/error"); });
    }, [jobId]);
    var displayDateTime = function (date, label) { return (React.createElement(Col, null,
        React.createElement("small", null,
            label,
            ":"),
        React.createElement("br", null),
        React.createElement("span", null,
            displayDate(date),
            " ",
            displayTime(date)))); };
    if (!pdfJob) {
        return React.createElement(SpinnerLoader, null);
    }
    return (React.createElement(Container, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 8, sm: 6 },
                React.createElement(Card, null,
                    React.createElement(Card.Header, null, "Job infromation"),
                    React.createElement(Card.Body, null,
                        React.createElement(Card.Title, null, "Job ID:"),
                        React.createElement(Card.Subtitle, null, pdfJob.id),
                        React.createElement(Card.Title, { className: "mt-3" }, "Job type:"),
                        React.createElement(Card.Subtitle, null,
                            React.createElement(JobTypeIcon, { type: pdfJob.type, id: pdfJob.id })),
                        React.createElement(Card.Title, { className: "mt-3" }, "Callback URL:"),
                        React.createElement(Card.Subtitle, null, pdfJob.callbackUrl),
                        React.createElement(Card.Title, { className: "mt-3" }, "Client Reference:"),
                        React.createElement(Card.Subtitle, null, pdfJob.clientReference),
                        React.createElement(Card.Title, { className: "mt-3" }, "PDF Type:"),
                        React.createElement(Card.Subtitle, null, pdfJob.pdfType)),
                    React.createElement(Card.Footer, { className: "text-muted" },
                        React.createElement(Row, null,
                            displayDateTime(pdfJob.createdAt, "Created"),
                            displayDateTime(pdfJob.lastUpdateAt, "Last update"))))),
            React.createElement(Col, { xs: 4, sm: 6 },
                React.createElement(Card, null,
                    React.createElement(Card.Header, null, "Job Status"),
                    React.createElement(Card.Body, null,
                        React.createElement(Card.Title, null,
                            React.createElement(JobStatusIcon, { status: pdfJob.status })),
                        React.createElement("hr", null),
                        React.createElement("a", { href: kibanaJobDetails(pdfJob.id), target: "_blank" },
                            React.createElement(CircleIcon, { description: "Kibana logs", icon: kibanaIcon }))),
                    Boolean(pdfJob.failureReason) && (React.createElement(Card.Footer, { className: "bg-danger" },
                        React.createElement("span", { className: "text-white" }, pdfJob.failureReason)))),
                React.createElement(PdfLayoutJsonViewer, { data: [
                        { title: "Header", content: pdfJob.headerJson },
                        { title: "Footer", content: pdfJob.footerJson },
                        {
                            title: "Body options",
                            content: pdfJob.bodyGenerationOptionsJson
                        }
                    ] }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, className: "mt-3" },
                React.createElement(AttachmentFilesList, { jobId: jobId, isOutputFile: true })),
            React.createElement(Col, { xs: 12, className: "mt-3" },
                React.createElement(AttachmentFilesList, { jobId: jobId })))));
};
var mapDispatchToProps = {
    loadSinglePdfJob: loadSinglePdfJob
};
export default connect(null, mapDispatchToProps)(JobDetails);
