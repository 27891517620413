export function createReducer(initState, actionReducerMap) {
    return function (state, action) {
        if (state === void 0) { state = initState; }
        var reducer = actionReducerMap[action.type];
        return reducer ? reducer(state, action) : state;
    };
}
export function createAction(type) {
    return function (payload, meta, error) {
        return {
            type: type,
            payload: payload,
            meta: meta,
            error: error
        };
    };
}
