var _a;
import { createReducer } from "@utils/storeUtils";
export var initialState = {
    uploaded: null
};
var uploadStatus = function (state, action) {
    var _a = action.payload, jobId = _a.jobId, message = _a.message;
    return {
        jobId: jobId,
        message: message,
        uploaded: action.type === "FILES_UPLOAD_SUCCESS"
    };
};
var uploadEnd = function (state, action) {
    return initialState;
};
var actionReducerMap = (_a = {},
    _a["FILES_UPLOAD_FAIL"] = uploadStatus,
    _a["FILES_UPLOAD_SUCCESS"] = uploadStatus,
    _a["CLEAR_FILES_UPLOAD_STATUS"] = uploadEnd,
    _a);
export default createReducer(initialState, actionReducerMap);
