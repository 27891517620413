import pdfService from "@services/pdfService";
import { createAction } from "@utils/storeUtils";
var fetchPdfJobs = createAction("FETCH_PDF_JOBS");
var fetchPdfJobsSuccess = createAction("FETCH_PDF_JOBS_SUCCESS");
var fetchPdfJobsFail = createAction("FETCH_PDF_JOBS_FAIL");
var fetchSinglePdfJob = createAction("FETCH_SINGLE_PDF_JOB");
var fetchSinglePdfJobSuccess = createAction("FETCH_SINGLE_PDF_JOB_SUCCESS");
var fetchSinglePdfJobFail = createAction("FETCH_SINGLE_PDF_JOB_FAIL");
var fetchInputFilesForJob = createAction("FETCH_INPUT_FILES_FOR_JOB");
var fetchInputFilesForJobSuccess = createAction("FETCH_INPUT_FILES_FOR_JOB_SUCCESS");
var fetchInputFilesForJobFail = createAction("FETCH_INPUT_FILES_FOR_JOB_FAIL");
var fetchOutputFilesForJob = createAction("FETCH_OUTPUT_FILES_FOR_JOB");
var fetchOutputFilesForJobSuccess = createAction("FETCH_OUTPUT_FILES_FOR_JOB_SUCCESS");
var fetchOutputFilesForJobFail = createAction("FETCH_OUTPUT_FILES_FOR_JOB_FAIL");
var filesUpload = createAction("FILES_UPLOAD");
var filesUploadSuccess = createAction("FILES_UPLOAD_SUCCESS");
var filesUploadFail = createAction("FILES_UPLOAD_FAIL");
export var clearUploadStatus = createAction("CLEAR_FILES_UPLOAD_STATUS");
export var addFilter = createAction("ADD_FILTER");
export var removeFilter = createAction("REMOVE_FILTER");
export var setFilters = createAction("SET_FILTERS");
export var changeOrderingDirection = createAction("CHANGE_ORDERING_DIRECTION");
export var changeOrderingKey = createAction("CHANGE_ORDERING_KEY");
export var setOrdering = createAction("SET_ORDERING");
export var loadPdfJobs = function (params) { return function (dispatch) {
    dispatch(fetchPdfJobs(params));
    return pdfService
        .loadPdfJobs(params)
        .then(function (_a) {
        var data = _a.data;
        return dispatch(fetchPdfJobsSuccess(data));
    })
        .catch(function (_a) {
        var error = _a.error;
        return dispatch(fetchPdfJobsFail(error));
    });
}; };
export var loadSinglePdfJob = function (params) { return function (dispatch) {
    dispatch(fetchSinglePdfJob(params));
    return pdfService
        .loadSinglePdfJob(params.jobId)
        .then(function (_a) {
        var data = _a.data;
        dispatch(fetchSinglePdfJobSuccess(data));
        return data;
    })
        .catch(function (_a) {
        var error = _a.error;
        dispatch(fetchSinglePdfJobFail(error));
        throw error;
    });
}; };
export var loadInputFilesForJob = function (params) { return function (dispatch) {
    dispatch(fetchInputFilesForJob(params));
    return pdfService
        .loadInputFilesForJob(params.jobId)
        .then(function (_a) {
        var data = _a.data;
        dispatch(fetchInputFilesForJobSuccess(data));
        return data;
    })
        .catch(function (_a) {
        var error = _a.error;
        return dispatch(fetchInputFilesForJobFail(error));
    });
}; };
export var loadOutputFilesForJob = function (params) { return function (dispatch) {
    dispatch(fetchOutputFilesForJob(params));
    return pdfService
        .loadOutputFilesForJob(params.jobId)
        .then(function (_a) {
        var data = _a.data;
        dispatch(fetchOutputFilesForJobSuccess(data));
        return data;
    })
        .catch(function (_a) {
        var error = _a.error;
        return dispatch(fetchOutputFilesForJobFail(error));
    });
}; };
export var uploadFiles = function (params) { return function (dispatch) {
    dispatch(filesUpload(params));
    return pdfService
        .uploadFiles(params)
        .then(function (_a) {
        var data = _a.data;
        return dispatch(filesUploadSuccess(data));
    })
        .catch(function (error) {
        dispatch(filesUploadFail({
            message: error.response.data.title
        }));
    });
}; };
