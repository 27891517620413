import React from "react";
import { CallbackComponent } from "redux-oidc";
import userManager from "@auth/userManager";
import { AppRoutes } from "@utils/enums";
import SpinnerLoader from "@components/SpinnerLoader/SpinnerLoader";
import { rehydrateNavigation } from "@talentech/login";
var CallbackPage = function (props) { return (React.createElement(CallbackComponent, { userManager: userManager, successCallback: function () {
        rehydrateNavigation({
            navigationPush: props.history.push
        });
    }, errorCallback: function () {
        props.history.push(AppRoutes.Error);
    } },
    React.createElement(SpinnerLoader, null))); };
export default CallbackPage;
