import { __assign } from "tslib";
import JobStatusFilter from "./Filters/JobStatusFilter";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { addFilter, removeFilter } from "@store/actions/pdf";
import { connect } from "react-redux";
import React from "react";
import JobTypeFilter from "./Filters/JobTypeFilter";
import RemoveFilterIcon from "@components/Icon/RemoveFilterIcon";
import { AvailableFilters, FilterDateRange } from "@utils/enums";
import { Col, Row } from "react-bootstrap";
import clx from "classnames";
import addDyas from "date-fns/add_days";
import { getFilters } from "@store/selectors/pdf";
import { displayDate } from "@utils/date";
var styles = require("./styles.scss");
var TableFiltering = function (props) {
    var setFilter = function (params) {
        var _a, _b;
        var filter = {};
        if (params.filterType === "DATE_RANGE") {
            filter = __assign(__assign({}, filter), (_a = {}, _a[params.filterName === FilterDateRange.CREATED
                ? AvailableFilters.CREATED_FROM
                : AvailableFilters.UPDATED_FROM] = params.filterValue[0], _a[params.filterName === FilterDateRange.CREATED
                ? AvailableFilters.CREATED_TO
                : AvailableFilters.UPDATED_TO] = addDyas(params.filterValue[1], 1), _a));
        }
        else {
            filter = (_b = {},
                _b[params.filterName] = params.filterValue,
                _b);
        }
        props.addFilter(filter);
    };
    var isDatePicked = function (dates) { return dates.length === 2; };
    var changeDateRange = function (dates, filterName) {
        if (isDatePicked(dates)) {
            setFilter({
                filterName: filterName,
                filterValue: dates,
                filterType: "DATE_RANGE"
            });
        }
    };
    var getCurrentRangeFilter = function (filter) {
        var start = props.filters[filter === FilterDateRange.CREATED
            ? AvailableFilters.CREATED_FROM
            : AvailableFilters.UPDATED_FROM];
        var stop = props.filters[filter === FilterDateRange.CREATED
            ? AvailableFilters.CREATED_TO
            : AvailableFilters.UPDATED_TO];
        var startDate = start && displayDate(start);
        var stopDate = stop && displayDate(stop);
        return !start && !stop ? "Select date" : startDate + " - " + stopDate;
    };
    var filters = [
        React.createElement(React.Fragment, null,
            React.createElement(JobTypeFilter, { onChange: setFilter }),
            React.createElement(RemoveFilterIcon, { filter: AvailableFilters.TYPE_NAME })),
        React.createElement(React.Fragment, null,
            React.createElement(Flatpickr, { options: {
                    mode: "range",
                    maxDate: "today"
                }, onChange: function (dates) { return changeDateRange(dates, FilterDateRange.CREATED); }, placeholder: getCurrentRangeFilter(FilterDateRange.CREATED), className: "form-control" }),
            React.createElement(RemoveFilterIcon, { filter: AvailableFilters.CREATED_FROM })),
        React.createElement(React.Fragment, null,
            React.createElement(Flatpickr, { id: "update-date", options: { mode: "range", maxDate: "today" }, onChange: function (dates) { return changeDateRange(dates, FilterDateRange.UPDATED); }, placeholder: getCurrentRangeFilter(FilterDateRange.UPDATED), className: "form-control" }),
            React.createElement(RemoveFilterIcon, { filter: AvailableFilters.UPDATED_FROM })),
        React.createElement(React.Fragment, null,
            React.createElement(JobStatusFilter, { onChange: setFilter }),
            React.createElement(RemoveFilterIcon, { filter: AvailableFilters.STATUS_NAME }))
    ];
    return (React.createElement("tr", null,
        React.createElement("td", null),
        filters.map(function (filter, i) { return (React.createElement("td", { key: i },
            React.createElement(Row, null,
                React.createElement(Col, { className: clx(styles.filterWrapper) }, filter)))); })));
};
var mapStateToProps = function (state) { return ({
    filters: getFilters(state)
}); };
var mapDispatchToProps = {
    addFilter: addFilter,
    removeFilter: removeFilter
};
export default connect(mapStateToProps, mapDispatchToProps)(TableFiltering);
