import { __awaiter, __generator } from "tslib";
import { Col, Container, Row } from "react-bootstrap";
import PdfJobs from "@components/PdfJobs/PdfJobs";
import { loadPdfJobs } from "@store/actions";
import { getLoadingStatus, getPagination, getPdfJobs } from "@store/selectors/pdf";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import Select from "@components/Select/Select";
import clx from "classnames";
import ReactPaginate from "react-paginate";
var styles = require("./styles.scss");
var MainPage = function () {
    var dispatch = useDispatch();
    var isLoadingJobs = useSelector(getLoadingStatus);
    var jobs = useSelector(getPdfJobs);
    var pagination = useSelector(getPagination);
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, dispatch(loadPdfJobs({ PageNumber: 1 }))];
                        case 1:
                            _a.sent();
                            return [2];
                    }
                });
            });
        }
        fetchData();
    }, []);
    return (React.createElement(Container, null,
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(PdfJobs, { jobs: jobs, isLoading: isLoadingJobs }))),
        React.createElement(Row, null,
            React.createElement(Col, { className: clx(styles.tableFooter) },
                React.createElement("div", { className: "float-left" },
                    React.createElement(ReactPaginate, { pageCount: pagination.totalPages, pageRangeDisplayed: 5, marginPagesDisplayed: 2, containerClassName: "pagination", activeClassName: "active", pageClassName: "page-item", pageLinkClassName: "page-link", breakClassName: "page-item disabled", breakLinkClassName: "page-link", previousLabel: "‹", nextLabel: "›", previousClassName: "page-item", nextClassName: "page-item", previousLinkClassName: "page-link", nextLinkClassName: "page-link", onPageChange: function (e) {
                            dispatch(loadPdfJobs({ PageNumber: e.selected + 1 }));
                        } })),
                React.createElement("div", { className: "float-right" },
                    React.createElement(Select, { onChange: function (params) { return dispatch(loadPdfJobs(params)); }, selectOptions: [5, 10, 25, 50] }))))));
};
export default MainPage;
