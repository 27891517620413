import { apiRequest, HttpMethodsList } from "@utils/api";
var pdfService = {
    loadPdfJobs: function (params) { return apiRequest("/Jobs", params); },
    loadSinglePdfJob: function (jobId) { return apiRequest("/Jobs/" + jobId, {}, false); },
    loadInputFilesForJob: function (jobId) {
        return apiRequest("/Jobs/" + jobId + "/inputfiles", {}, false);
    },
    loadOutputFilesForJob: function (jobId) {
        return apiRequest("/Jobs/" + jobId + "/outputfiles", {}, false);
    },
    uploadFiles: function (params) {
        return apiRequest("/Pdf/" + params.jobType, {}, false, HttpMethodsList.POST, { "Content-Type": "application/json" }, JSON.stringify(params.data));
    }
};
export default pdfService;
