import { __read } from "tslib";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
var DropdownSelect = function (_a) {
    var options = _a.options, dropdownName = _a.dropdownName, onDropdownSelect = _a.onDropdownSelect, dropdownPlaceholder = _a.dropdownPlaceholder, dropdownKey = _a.dropdownKey;
    var _b = __read(useState(null), 2), dropdownValue = _b[0], setDropdownValue = _b[1];
    useEffect(function () {
        var _a;
        onDropdownSelect((_a = {},
            _a[dropdownKey] = dropdownValue,
            _a));
    }, [dropdownValue]);
    return (React.createElement(Dropdown, null,
        React.createElement(Dropdown.Toggle, { id: dropdownName }, dropdownValue || dropdownPlaceholder),
        React.createElement(Dropdown.Menu, null, options.map(function (option) {
            return (React.createElement(Dropdown.Item, { key: option, eventKey: option, active: dropdownValue === option, onSelect: function (e) { return setDropdownValue(e); } }, option));
        }))));
};
export default DropdownSelect;
