import React from "react";
import { Table } from "react-bootstrap";
import { displayDate, displayTime } from "@utils/date";
import JobStatusIcon from "@components/Icon/JobStatusIcon";
import JobTypeIcon from "@components/Icon/JobTypeIcon";
import TableFiltering from "@components/TableFiltering/TableFiltering";
import { Link } from "react-router-dom";
import clx from "classnames";
import TableOrdering from "@components/TableOrdering/TableOrdering";
import { SortingKeys } from "@utils/enums";
import SpinnerLoader from "@components/SpinnerLoader/SpinnerLoader";
var styles = require("./styles.scss");
var PdfJobs = function (props) {
    var jobs = props.jobs, isLoading = props.isLoading;
    var displayDateTime = function (date) {
        return displayDate(date) + " " + displayTime(date);
    };
    return (React.createElement(Table, { hover: true, responsive: "sm" },
        React.createElement("thead", null,
            React.createElement("tr", { className: clx(styles.headerRow) },
                React.createElement("th", null, "#"),
                React.createElement("th", null, "Type name"),
                React.createElement("th", null,
                    React.createElement("div", { className: clx(styles.orderable) },
                        React.createElement("span", null, "Created"),
                        " ",
                        React.createElement(TableOrdering, { orderBy: SortingKeys.CREATED }))),
                React.createElement("th", null,
                    React.createElement("div", { className: clx(styles.orderable) },
                        React.createElement("span", null, "Last update"),
                        " ",
                        React.createElement(TableOrdering, { orderBy: SortingKeys.LAST_UPDATE }))),
                React.createElement("th", null, "Status name"))),
        React.createElement("tbody", null, isLoading ? (React.createElement("tr", null,
            React.createElement("td", { colSpan: 5 },
                React.createElement(SpinnerLoader, null)))) : (React.createElement(React.Fragment, null,
            React.createElement(TableFiltering, null),
            jobs.map(function (job, i) {
                var rowElements = [
                    React.createElement(JobTypeIcon, { id: job.id, type: job.type, onlyIcon: true, key: "job-type-icon" }),
                    displayDateTime(job.createdAt),
                    displayDateTime(job.lastUpdateAt),
                    React.createElement(JobStatusIcon, { status: job.status, key: "job-status-icon" })
                ];
                return (React.createElement("tr", { key: job.id, className: styles.clickableRow },
                    React.createElement("th", { scope: "row" },
                        React.createElement(Link, { to: "/job/" + job.id }, i + 1)),
                    rowElements.map(function (rowElement, index) { return (React.createElement("td", { align: "center", key: index },
                        React.createElement(Link, { to: "/job/" + job.id }, rowElement))); })));
            }))))));
};
export default PdfJobs;
