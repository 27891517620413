import { __assign, __rest } from "tslib";
import React from "react";
import { Redirect, Route } from "react-router";
import { useSelector } from "react-redux";
import { getOidcUser } from "@store/selectors/oidc";
import { AppRoutes } from "@utils/enums";
var PrivateRoute = function (_a) {
    var Component = _a.component, rest = __rest(_a, ["component"]);
    var user = useSelector(getOidcUser);
    if (user === undefined) {
        return null;
    }
    return (React.createElement(Route, __assign({}, rest, { render: function (props) {
            return !user || user.expired ? (React.createElement(Redirect, { to: {
                    pathname: AppRoutes.Home
                } })) : (React.createElement(Component, __assign({}, props)));
        } })));
};
export default PrivateRoute;
