export var JobStatus;
(function (JobStatus) {
    JobStatus[JobStatus["NEW"] = 1] = "NEW";
    JobStatus[JobStatus["VALIDATED"] = 10] = "VALIDATED";
    JobStatus[JobStatus["IN_PROGRESS"] = 20] = "IN_PROGRESS";
    JobStatus[JobStatus["APP_ERROR"] = 98] = "APP_ERROR";
    JobStatus[JobStatus["FAILED"] = 99] = "FAILED";
    JobStatus[JobStatus["FINISHED"] = 100] = "FINISHED";
})(JobStatus || (JobStatus = {}));
export var JobType;
(function (JobType) {
    JobType[JobType["ALL_TO_ONE"] = 1] = "ALL_TO_ONE";
    JobType[JobType["ONE_BY_ONE"] = 2] = "ONE_BY_ONE";
})(JobType || (JobType = {}));
export var AppRoutes;
(function (AppRoutes) {
    AppRoutes["Home"] = "/";
    AppRoutes["Error"] = "/error";
    AppRoutes["OidcCallback"] = "/callback.html";
    AppRoutes["SingleJob"] = "/job/:id";
    AppRoutes["UploadDocument"] = "/upload";
})(AppRoutes || (AppRoutes = {}));
export var AvailableFilters;
(function (AvailableFilters) {
    AvailableFilters["TYPE_NAME"] = "TypeName";
    AvailableFilters["STATUS_NAME"] = "StatusNames";
    AvailableFilters["CREATED_FROM"] = "CreatedFrom";
    AvailableFilters["CREATED_TO"] = "CreatedTo";
    AvailableFilters["UPDATED_FROM"] = "UpdatedFrom";
    AvailableFilters["UPDATED_TO"] = "UpdatedTo";
})(AvailableFilters || (AvailableFilters = {}));
export var SortingKeys;
(function (SortingKeys) {
    SortingKeys["CREATED"] = "CreatedAt";
    SortingKeys["LAST_UPDATE"] = "LastUpdateAt";
})(SortingKeys || (SortingKeys = {}));
export var UploadJobType;
(function (UploadJobType) {
    UploadJobType["ONE_BY_ONE"] = "oneByOne";
    UploadJobType["ALL_TO_ONE"] = "manyToOne";
})(UploadJobType || (UploadJobType = {}));
export var LoadAttchmentFileTypeActions;
(function (LoadAttchmentFileTypeActions) {
    LoadAttchmentFileTypeActions["LOAD_OUTPUT_FILE"] = "loadOutputFilesForJob";
    LoadAttchmentFileTypeActions["LOAD_INPUT_FILE"] = "loadInputFilesForJob";
})(LoadAttchmentFileTypeActions || (LoadAttchmentFileTypeActions = {}));
export var AttachmentFileTableTitles;
(function (AttachmentFileTableTitles) {
    AttachmentFileTableTitles["INPUT_FILES"] = "Input files";
    AttachmentFileTableTitles["OUTPUT_FILES"] = "Output files";
})(AttachmentFileTableTitles || (AttachmentFileTableTitles = {}));
export var UserRoles;
(function (UserRoles) {
    UserRoles["PdfServiceUser"] = "Portal.PdfService.User";
})(UserRoles || (UserRoles = {}));
export var FilterDateRange;
(function (FilterDateRange) {
    FilterDateRange["CREATED"] = "CREATED";
    FilterDateRange["UPDATED"] = "UPDATED";
})(FilterDateRange || (FilterDateRange = {}));
export var PdfTypes;
(function (PdfTypes) {
    PdfTypes["PDF_A_1A"] = "PDF_A_1A";
    PdfTypes["PDF_A_1B"] = "PDF_A_1B";
    PdfTypes["PDF_A_2A"] = "PDF_A_2A";
    PdfTypes["PDF_A_3A"] = "PDF_A_3A";
    PdfTypes["PDF_A_2B"] = "PDF_A_2B";
    PdfTypes["PDF_A_2U"] = "PDF_A_2U";
    PdfTypes["PDF_A_3B"] = "PDF_A_3B";
    PdfTypes["PDF_A_3U"] = "PDF_A_3U";
    PdfTypes["v_1_3"] = "v_1_3";
    PdfTypes["v_1_4"] = "v_1_4";
    PdfTypes["v_1_5"] = "v_1_5";
    PdfTypes["v_1_6"] = "v_1_6";
    PdfTypes["v_1_7"] = "v_1_7";
    PdfTypes["PDF_UA_1"] = "PDF_UA_1";
    PdfTypes["PDF_X_1A"] = "PDF_X_1A";
    PdfTypes["PDF_X_3"] = "PDF_X_3";
})(PdfTypes || (PdfTypes = {}));
export var PdfGenerator;
(function (PdfGenerator) {
    PdfGenerator["AsposeGenerator"] = "AsposeGenerator";
    PdfGenerator["EssentialObjectGenerator"] = "EssentialObjectGenerator";
})(PdfGenerator || (PdfGenerator = {}));
