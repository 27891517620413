import React from "react";
import { Accordion, Card } from "react-bootstrap";
import ReactJson from "react-json-view";
var PdfLayoutJsonViewer = function (props) {
    return (React.createElement(Accordion, { className: "mt-2" }, props.data.map(function (jsonPreview, index) {
        return Boolean(jsonPreview.content) ? (React.createElement(Card, { key: jsonPreview.title },
            React.createElement(Accordion.Toggle, { as: Card.Header, eventKey: index.toString() }, jsonPreview.title),
            React.createElement(Accordion.Collapse, { eventKey: index.toString() },
                React.createElement(Card.Body, null,
                    React.createElement(ReactJson, { src: JSON.parse(jsonPreview.content) }))))) : null;
    })));
};
export default PdfLayoutJsonViewer;
