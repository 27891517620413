import { hot } from "react-hot-loader/root";
import React from "react";
import { Route } from "react-router-dom";
import HomePage from "@pages/home/HomePage";
import CallbackPage from "@auth/Callback";
import ErrorPage from "@pages/error/ErrorPage";
import JobDetails from "@pages/jobDetails/JobDetails";
import { AppRoutes } from "@utils/enums";
import UploadDocuments from "@pages/uploadDocuments/UploadDocuments";
import PrivateRoute from "@auth/PrivateRoute/PrivateRoute";
var Routes = function () { return (React.createElement(React.Fragment, null,
    React.createElement(Route, { path: AppRoutes.Home, exact: true, component: HomePage }),
    React.createElement(Route, { path: AppRoutes.OidcCallback, component: CallbackPage }),
    React.createElement(PrivateRoute, { path: AppRoutes.SingleJob, component: JobDetails }),
    React.createElement(PrivateRoute, { path: AppRoutes.UploadDocument, component: UploadDocuments }),
    React.createElement(Route, { path: AppRoutes.Error, component: ErrorPage }))); };
export default hot(Routes);
