import { __assign } from "tslib";
import { applyMiddleware, createStore } from "redux";
import { loadUser } from "redux-oidc";
import thunkMiddleware from "redux-thunk";
import reducer from "@store/reducer";
import userManager from "@auth/userManager";
import { composeWithDevTools } from "redux-devtools-extension";
import { initialState as initailPdf } from "@store/reducer/pdf";
import { initialState as initailUpload } from "@store/reducer/upload";
import pdfJobsMiddleware from "@store/middlewares/pdfJobsMiddleware";
import { appsMock } from "@mocks/apps.mock";
var initialState = {
    oidc: {
        user: undefined,
        isLoadingUser: false
    },
    pdf: __assign({}, initailPdf),
    upload: __assign({}, initailUpload),
    apps: appsMock
};
var createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunkMiddleware, pdfJobsMiddleware))(createStore);
var store = createStoreWithMiddleware(reducer, initialState);
loadUser(store, userManager);
export var mockStore = function (data) {
    if (data === void 0) { data = {}; }
    return (__assign(__assign({}, initialState), data));
};
export default store;
