import { __assign } from "tslib";
import { loadPdfJobs, setFilters, setOrdering } from "@store/actions/pdf";
import { getFilters, getOrdering } from "@store/selectors/pdf";
import { AvailableFilters } from "@utils/enums";
var pdfJobsMiddleware = function (_a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return function (next) { return function (action) {
        var payload = action.payload, type = action.type;
        if (type === "ADD_FILTER" ||
            type === "REMOVE_FILTER") {
            var filters = {};
            var currentFilters_1 = getFilters(getState());
            if (type === "ADD_FILTER") {
                var filtersToSet = payload;
                filters = __assign(__assign({}, currentFilters_1), filtersToSet);
            }
            if (type === "REMOVE_FILTER") {
                filters = Object.keys(currentFilters_1).reduce(function (object, key) {
                    var _a;
                    if (key !== payload) {
                        var value = currentFilters_1[key];
                        object = __assign(__assign({}, object), (_a = {}, _a[key] = value, _a));
                    }
                    if (payload === AvailableFilters.CREATED_FROM) {
                        delete object[AvailableFilters.CREATED_TO];
                    }
                    if (payload === AvailableFilters.UPDATED_FROM) {
                        delete object[AvailableFilters.UPDATED_TO];
                    }
                    return object;
                }, {});
            }
            dispatch(setFilters(filters));
            dispatch(loadPdfJobs({ PageNumber: 1 }));
        }
        if (type === "CHANGE_ORDERING_DIRECTION" ||
            type === "CHANGE_ORDERING_KEY") {
            var currentOrdering = getOrdering(getState());
            var ordering = {};
            if (type === "CHANGE_ORDERING_DIRECTION") {
                ordering = __assign(__assign({}, currentOrdering), { Ascending: !currentOrdering.Ascending });
            }
            if (type === "CHANGE_ORDERING_KEY") {
                ordering = __assign(__assign({}, currentOrdering), { OrderBy: payload });
            }
            dispatch(setOrdering(ordering));
            dispatch(loadPdfJobs({ PageNumber: 1 }));
        }
        return next(action);
    }; };
};
export default pdfJobsMiddleware;
