import { __read } from "tslib";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Modal, Table } from "react-bootstrap";
import { displayDate, displayTime } from "@utils/date";
import { loadInputFilesForJob, loadOutputFilesForJob } from "@store/actions/pdf";
import { AttachmentFileTableTitles, LoadAttchmentFileTypeActions } from "@utils/enums";
import ErrorMessageModal from "@components/Icon/ErrorMessageModal";
import { fileRequest } from "@utils/api";
var AttachmentFilesList = function (props) {
    var jobId = props.jobId, isOutputFile = props.isOutputFile;
    var _a = __read(useState([]), 2), attachmentFiles = _a[0], setAttachmentFiles = _a[1];
    var _b = __read(useState(false), 2), modalStatus = _b[0], setModalStatus = _b[1];
    useEffect(function () {
        props[isOutputFile
            ? LoadAttchmentFileTypeActions.LOAD_OUTPUT_FILE
            : LoadAttchmentFileTypeActions.LOAD_INPUT_FILE]({
            jobId: jobId
        }).then(function (response) { return setAttachmentFiles(response); });
    }, [jobId]);
    var displayDateTime = function (date) {
        return displayDate(date) + " " + displayTime(date);
    };
    var downloadFile = function (fileId, filename) {
        fileRequest("/" + (isOutputFile ? "OutputFiles" : "InputFiles") + "/" + fileId + "/download")
            .then(function (response) {
            var url = window.URL.createObjectURL(new Blob([response.data]));
            var link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
            .catch(function () { return setModalStatus(true); });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, null,
            React.createElement(Card.Header, null, isOutputFile
                ? AttachmentFileTableTitles.OUTPUT_FILES
                : AttachmentFileTableTitles.INPUT_FILES),
            React.createElement(Card.Body, null,
                React.createElement(Table, { size: "sm", hover: true, responsive: true, id: (isOutputFile ? "output" : "input") + "-files" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "#"),
                            !isOutputFile && React.createElement("th", null, "Original name"),
                            React.createElement("th", null, "Service name"),
                            React.createElement("th", null, "Error message"),
                            React.createElement("th", null, "Download time"),
                            isOutputFile && React.createElement("th", null, "User friendly name"),
                            React.createElement("th", null, "Download file"))),
                    React.createElement("tbody", null, attachmentFiles.map(function (file, i) { return (React.createElement("tr", { key: file.id },
                        React.createElement("th", { scope: "row" }, i + 1),
                        !isOutputFile && React.createElement("td", null,
                            " ",
                            file.originalFileName),
                        React.createElement("td", null,
                            " ",
                            file.serviceFileName),
                        React.createElement("td", null, Boolean(file.errorMessage) && (React.createElement(ErrorMessageModal, { errorId: file.serviceFileName, errorMessage: file.errorMessage }))),
                        React.createElement("td", null, file.downloadedAt && displayDateTime(file.downloadedAt)),
                        isOutputFile && React.createElement("td", null,
                            " ",
                            file.userfriendlyFileName),
                        React.createElement("td", null, file.isDeleted ? ("File is deleted") : (React.createElement(Button, { variant: "primary", size: "sm", onClick: function () {
                                return downloadFile(file.id, isOutputFile
                                    ? file.userfriendlyFileName
                                    : file.originalFileName);
                            } }, "Download"))))); }))))),
        isOutputFile && (React.createElement(Modal, { show: modalStatus, onHide: function () { return setModalStatus(!modalStatus); } },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Error")),
            React.createElement(Modal.Body, null, "Can't download file")))));
};
var mapDispatchToProps = {
    loadInputFilesForJob: loadInputFilesForJob,
    loadOutputFilesForJob: loadOutputFilesForJob
};
export default connect(null, mapDispatchToProps)(AttachmentFilesList);
