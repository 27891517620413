import React from "react";
import { useSelector } from "react-redux";
import MainPage from "@pages/main/MainPage";
import { getOidcUser, getOidcUserRoles } from "@store/selectors/oidc";
import { isAuthorizedUser } from "@utils/user";
import userManager from "@auth/userManager";
import { isRoutePersisted, LoginPage, NavigationRehydrator } from "@talentech/login";
import "@talentech/login/dist/index.css";
import { useHistory } from "react-router-dom";
var HomePage = function () {
    var user = useSelector(getOidcUser);
    var roles = useSelector(getOidcUserRoles);
    if (!user || user.expired || !isAuthorizedUser(roles)) {
        var isNotAuthorized = !!user && !user.expired;
        return (React.createElement(LoginPage, { applicationName: "PDF SERVICE", isAuthorized: !isNotAuthorized, userManager: userManager }));
    }
    return isRoutePersisted() ? (React.createElement(NavigationRehydrator, { navigationPush: useHistory().push })) : (React.createElement(MainPage, null));
};
export default HomePage;
