var _a, _b;
import React from "react";
import { JobStatus } from "@utils/enums";
import { Badge } from "react-bootstrap";
var badgeColorMapping = (_a = {},
    _a[JobStatus.NEW] = "primary",
    _a[JobStatus.VALIDATED] = "secondary",
    _a[JobStatus.IN_PROGRESS] = "info",
    _a[JobStatus.FAILED] = "warning",
    _a[JobStatus.APP_ERROR] = "danger",
    _a[JobStatus.FINISHED] = "success",
    _a);
var JobStatusNameMapping = (_b = {},
    _b[JobStatus.NEW] = "New",
    _b[JobStatus.VALIDATED] = "Validated",
    _b[JobStatus.IN_PROGRESS] = "In progress",
    _b[JobStatus.FAILED] = "Failed",
    _b[JobStatus.APP_ERROR] = "Application error",
    _b[JobStatus.FINISHED] = "Finished",
    _b);
var StatusIcon = function (props) { return (React.createElement(Badge, { variant: badgeColorMapping[props.status] }, JobStatusNameMapping[props.status])); };
export default StatusIcon;
